<!--详情页面-->
<template>
    <div class="home" v-loading="pageLoading">
        <!--中间主体内容-->
        <!--        标题-->
        <div class="detail-title font-bold">{{info.bug_title || ''}}</div>
        <div class="detail-title-line"
             :style="{background: `linear-gradient(to right, transparent, ${color_xiaofu})`}"></div>
        <!--        当前内容-->
        <div class="detail-list">
            <div class="detail-content" v-html="info.bug_content || ''" @click="imageEnlargement"></div>
            <div class="flex align-center justify-between detail-info">
                <div class="detail-auth flex justify-between align-center">
                    <span class="mar-rig font-bold">楼主.</span>
                    <template v-if="info.user_id">
                        <span class="iconfont icon-yonghu color-gray-more"></span>
                        <span class="color-panghu auth-name">{{info.user_name || ''}}</span>
                        <div class="info-level iconfont icon-dengji1 pos-rel color-jingxiang">
                            <span class="level-num color-white">{{getExpLevel(info.exp_num).level}}</span>
                        </div>
                    </template>
                    <span class="color-gray-more" v-else>游客</span>
                </div>
                <div class="flex">
                    <div class="inline-flex align-center color-gray detail-big">
                        <span class="iconfont icon-a851"></span>
                        <span class="mar-rig">{{info.bug_hits || 0}}</span>
                        <span class="iconfont icon-huifu"></span>
                        <span class="mar-rig">{{info.total || 0}}</span>
                        <span class="mar-rig">{{info.create_time}}</span>
                    </div>
                    <!--收藏-->
                    <span class="cursor-hand iconfont collect-btn" v-if="isLogin"
                          :class="info.is_collect ? 'color-xiaofu icon-shoucang1' : 'color-gray-more icon-shoucang'"
                          :title="info.is_collect ? '取消收藏' : '收藏'" @click="handleCollect"></span>
                    <span class="cursor-hand color-theme mar-left" @click="jumpEditor">回复</span>
                    <span class="cursor-hand color-meimei mar-left"
                          v-if="bugDelAuth" @click="handleDelBug">删除</span>
                </div>
            </div>
            <div class="align-center justify-between color-gray detail-little">
                <div class="flex align-center">
                    <span class="iconfont icon-a851"></span>
                    <span class="mar-rig">{{info.bug_hits || 0}}</span>
                    <span class="iconfont icon-huifu"></span>
                    <span>{{info.total || 0}}</span>
                </div>
                <div>{{info.create_time}}</div>
            </div>
        </div>
        <div class="detail-title-line"
             :style="{background: `linear-gradient(to right, ${color_xiaofu}, transparent)`}"></div>
        <!--        回复列表-->
        <div v-loading="listLoading">
            <div class="detail-list" v-for="(item,index) in replyList" :key="item.reply_id"
                 :style="{borderBottom:`1px solid ${color_xiaofu}`}">
                <div class="detail-content" v-html="item.content || ''" @click="imageEnlargement"></div>
                <div class="flex align-center justify-between detail-info">
                    <div class="detail-auth flex justify-between align-center">
                        <span class="mar-rig color-gray">{{(page - 1) * size + index + 1}}楼.</span>
                        <template v-if="item.reply_user_id">
                            <span class="iconfont icon-yonghu color-gray-more"></span>
                            <span class="color-panghu auth-name">{{item.user_name || ''}}</span>
                            <div class="info-level iconfont icon-dengji1 pos-rel color-jingxiang">
                                <span class="level-num color-white">{{getExpLevel(item.exp_num).level}}</span>
                            </div>
                        </template>
                        <span class="color-gray-more" v-else>游客</span>
                    </div>
                    <div>
                        <span class="mar-rig color-gray">{{item.create_time || ""}}</span>
                        <span class="cursor-hand color-theme" @click="showItem(index, item.reply_total)">回复{{item.reply_total > 0 ? `(${item.reply_total})` : ''}}</span>
                        <span class="cursor-hand color-meimei mar-left" @click="handleDelReply(item, 1)"
                              v-if="(userInfo.user_id === item.reply_user_id) || ['1','2'].includes(userInfo.admin)">删除</span>
                    </div>
                </div>
                <div class="reply-box">
                    <!--评论中的回复列表-->
                    <!--                楼中楼-->
                    <div class="reply-item size-s border-bottom" v-for="c_item in (item.children || [])"
                         :key="c_item.reply_id">
                        <div>
                            <span class="font-bold">{{c_item.reply_user_id ? c_item.user_name : '游客'}}</span>
                            <span class="color-gray-more" v-if="c_item.be_reply_user_name"> 回复 {{c_item.be_reply_user_name}}</span>：
                            <span class="color-gray">{{c_item.content || ""}}</span></div>
                        <div class="flex align-center justify-between">
                            <span class="color-gray-more">{{c_item.create_time || ""}}</span>
                            <div>
                                <span class="cursor-hand color-theme" @click="replyItem(index, c_item)">回复</span>
                                <span class="cursor-hand color-meimei mar-left"
                                      @click="handleDelReply(c_item, 2, index)"
                                      v-if="(userInfo.user_id === c_item.reply_user_id) || ['1','2'].includes(userInfo.admin)">删除</span>
                            </div>
                        </div>
                    </div>
                    <div class="reply-input" v-if="replyData.selInd === index">
                        <div class="color-gray-more" v-if="replyData.be_reply_user_name">回复
                            {{replyData.be_reply_user_name}}
                        </div>
                        <el-input
                                :ref="`reply${index}`"
                                type="textarea"
                                :rows="3"
                                placeholder="请输入回复的内容"
                                v-model="replyData.content">
                        </el-input>
                        <!--        回复提交按钮-->
                        <div class="replay-handle flex align-center">
                            <template v-if="!isLogin">
                                <div class="size-s mar-rig color-gray" v-if="isReplyAuth">
                                    你当前为游客状态，是否
                                    <router-link to="login" class="color-theme">登录</router-link>
                                    后再发布？
                                </div>
                                <div class="size-s mar-rig color-gray" v-else>
                                    你当前为游客状态，请
                                    <router-link to="login" class="color-theme">登录</router-link>
                                    后再发布。
                                </div>
                            </template>
                            <el-button class="replay-btn" type="primary" size="mini"
                                       :loading="itemLoading || false" :disabled="(!isLogin && !isReplyAuth)"
                                       @click="handleItem(item)">回复
                            </el-button>
                        </div>
                    </div>
                </div>
            </div>
            <!--        分页-->
            <div class="text-right" :style="{padding: `${pageCss.padding || 20}px 0`}">
                <el-pagination
                        :small="pageCss.small || false"
                        :pager-count="pageCss.pager_count || 9"
                        background
                        layout="prev, pager, next"
                        :total="total"
                        :page-size="size"
                        :current-page.sync="page"
                        @current-change="getReplyList"
                >
                </el-pagination>
            </div>
        </div>
        <!--        编辑框-->
        <div id="editor-box" class="bg-white">
            <editor ref="tinEditor" placeholder="请输入回复的内容..."></editor>
        </div>
        <!--        回复提交按钮-->
        <div class="detail-handle flex align-center bg-white">
            <template v-if="!isLogin">
                <div class="size-s mar-rig" v-if="isReplyAuth">
                    你当前为游客状态，是否
                    <router-link to="login" class="color-theme">登录</router-link>
                    后再发布？
                </div>
                <div class="size-s mar-rig" v-else>
                    你当前为游客状态，请
                    <router-link to="login" class="color-theme">登录</router-link>
                    后再发布。
                </div>
            </template>
            <el-button type="primary" size="small" :disabled="(!isLogin && !isReplyAuth)" :loading="replyLoading"
                       @click="handleReply">回复
            </el-button>
        </div>
        <!--返回顶部按钮-->
        <to-top :right="btnRight"></to-top>
        <!--浮动回复按钮-->
        <fixed-btn icon="icon-huifu1" :bg="`linear-gradient(to right, rgba(0,0,0,0.2), ${color})`" bottom="20px"
                   :right="btnRight + 'px'" @tap="jumpEditor"></fixed-btn>
    </div>
</template>

<script>
    // @ is an alias to /src
    import ToTop from "../components/ToTop";
    import FixedBtn from '../components/FixedBtn';
    import app from '@/utils/app.js';

    import editor from '@/components/Editor.vue';
    import {bugApi, collectApi} from '../api/api';
    import {MessageBox} from "element-ui";

    export default {
        name: 'Detail',
        components: {
            ToTop,
            FixedBtn, editor
        },
        computed: {
            fixed() {
                return this.$store.state.searchFixed;
            },
            // 分页页码相关,自适应兼容处理
            pageCss() {
                return this.$store.state.pageCss;
            },
            // 右下角的浮动按钮
            btnRight() {
                const w = window.screen.width;
                return w < 500 ? 20 : 130;
            },
            // 用户数据
            userInfo() {
                return this.$store.state.userInfo;
            },
            // 是否游客回复权限
            isReplyAuth() {
                return (this.$store.getters.getSettingById("visitor_reply") === "1");
            },
            // 是否有登录
            isLogin() {
                return this.$store.state.isLogin;
            },
            // 删除bug权限
            bugDelAuth() {
                const {userInfo, info} = this;
                return ((userInfo.user_id === info.user_id) || ['1', '2'].includes(userInfo.admin))
            }
        },
        data() {
            return {
                bug_id: "", // 上个页面传来的id
                color: app.color,
                color_xiaofu: app.color_xiaofu,
                replyLoading: false,
                replyData: { // 对评论回复的内容
                    content: '', // 内容
                },
                itemLoading: false, // 楼中楼回复的loading
                info: {}, // 主数据
                pageLoading: false, // 页面总loading
                listLoading: false, // 列表Loading
                collectLoading: false, // 收藏loading
                replyList: [], // 回复列表
                total: 0,
                size: 10,
                page: 1,
            }
        },
        async mounted() {
            this.bug_id = this.$route.query.id;
            await this.getInfo();
            await this.getIsCollect();
        },
        methods: {
            // 点击跳转到编辑框
            jumpEditor() {
                location.href = "#editor-box";
            },
            // 楼中楼点击子回复
            replyItem(ind, c_item) {
                const ref = `reply${ind}`,
                    r_item = this.replyList[ind];
                this.replyData = {
                    parent_id: r_item.reply_id,
                    be_reply_user_name: c_item.reply_user_id ? c_item.user_name : '游客',
                    be_reply_user_id: c_item.reply_user_id || null,
                    selInd: ind,
                    level: '2',
                    content: this.replyData.content
                };
                this.$forceUpdate();
                this.$nextTick(() => {
                    this.$refs[ref][0].focus();
                });
            },
            // 获取详情数据
            async getInfo() {
                const {bug_id} = this;
                this.pageLoading = true;
                const req = await app.ajax(bugApi.searchDetail, {bug_id});
                this.pageLoading = false;
                if (req.code === 1) {
                    this.info = req.data || {};
                    document.title = this.info.bug_title;
                    await this.getReplyList();
                } else {
                    app.alert(req.msg, 'error');
                }
            },
            // 回复列表
            async getReplyList() {
                const {bug_id, page, size} = this;
                this.listLoading = true;
                const params = {
                    page,
                    size,
                    parent_id: bug_id
                }
                const req = await app.ajax(bugApi.searchReplyList, params);
                this.listLoading = false;
                if (req.code === 1) {
                    this.replyList = req.data || [];
                    this.total = req.total || 0;
                } else {
                    app.alert(req.msg, 'error');
                }
            },
            // 点击展示子回复
            // refuesh 是否刷新数据
            async showItem(ind, total, refuesh) {
                const {replyList} = this,
                    r_item = replyList[ind];
                if ((total > 0 && (!r_item.children || r_item.children.length === 0)) || refuesh) {
                    this.replyList[ind].loading = true;
                    const req = await app.ajax(bugApi.searchReplyItem, {parent_id: r_item.reply_id});
                    this.replyList[ind].loading = false;
                    if (req.code === 1) {
                        this.replyList[ind].children = req.data || [];
                        this.replyList[ind].reply_total = req.data.length;
                        this.$forceUpdate();
                    } else {
                        app.alert(req.msg, 'error');
                    }

                } else {
                    // 楼中楼点击回复
                    const ref = `reply${ind}`;
                    this.replyData = {
                        parent_id: r_item.reply_id,
                        selInd: ind,
                        level: '2',
                        content: this.replyData.content
                    };
                    this.$forceUpdate();
                    this.$nextTick(() => {
                        this.$refs[ref][0].focus();
                    });
                }
            },
            // 主回复提交
            async handleReply() {
                const {bug_id} = this,
                    replyContent = this.$refs.tinEditor.contentHtml;
                if (!replyContent) {
                    app.alert('请输入内容', 'error');
                    return;
                }
                const params = {
                    parent_id: bug_id,
                    level: '1',
                    content: replyContent,
                }
                this.replyLoading = true;
                const req = await this.replySubmit(params);
                this.replyLoading = false;
                if (req.code === 1) {
                    app.alert('添加成功', 'success');
                    this.$refs.tinEditor.init();
                    await this.getReplyList();
                } else {
                    app.alert(req.msg, 'error');
                }
            },
            // 子回复提交
            async handleItem() {
                const {replyData} = this;
                if (!replyData.content) {
                    app.alert('请输入内容', 'error');
                    return;
                }
                this.itemLoading = true;
                const req = await this.replySubmit(replyData);
                this.itemLoading = false;
                if (req.code === 1) {
                    app.alert('添加成功', 'success');
                    this.replyData = {};
                    await this.showItem(replyData.selInd, 0, true);
                } else {
                    app.alert(req.msg, 'error');
                }
            },
            // 回复提交
            async replySubmit(params) {
                return await app.ajax(bugApi.addReply, params);
            },
            // 查询是否收藏
            async getIsCollect() {
                const {isLogin, bug_id} = this;
                if (!isLogin) {
                    return;
                }
                const req = await app.ajax(collectApi.searchIsCollect, {bug_id});
                if (req.code === 1) {
                    this.$set(this.info, 'is_collect', req.data);
                }
            },
            // 收藏
            async handleCollect() {
                const {info, collectLoading} = this;
                let req;
                if (collectLoading) return;
                this.collectLoading = true;
                if (info.is_collect) { // 取消收藏
                    req = await app.ajax(collectApi.delCollect, {bug_id: info.bug_id});
                } else { // 收藏
                    const params = {
                        bug_id: info.bug_id,
                        bug_title: info.bug_title,
                        bug_user: info.user_name,
                        type_name: info.type_name
                    }
                    req = await app.ajax(collectApi.addCollect, params);
                }
                this.collectLoading = false;
                if (req.code === 1) {
                    app.alert(`${info.is_collect ? '取消收藏成功' : '收藏成功'}`, 'success');
                    await this.getIsCollect();
                } else {
                    app.alert(req.msg, 'error');
                }
            },
            // 删除bug
            handleDelBug() {
                const {info} = this;
                MessageBox.confirm('是否删除当前文章？', '', {
                    confirmButtonText: '删除',
                    cancelButtonText: '取消',
                    type: 'warning',
                    beforeClose: async (action, instance, done) => {
                        if (action === 'confirm') {
                            instance.confirmButtonLoading = true;
                            instance.confirmButtonText = '删除中...';
                            const req = await app.ajax(bugApi.updateBug, {bug_id: info.bug_id});
                            done();
                            instance.confirmButtonLoading = false;
                            if (req.code === 1) {
                                app.alert('删除成功', 'success');
                                await this.$router.replace('home');
                            } else {
                                app.alert(req.msg, 'error')
                            }
                        } else {
                            done();
                        }
                    }
                }).then(() => {
                }).catch(() => {
                });
            },
            // 删除回复
            handleDelReply(item, level, ind) {
                MessageBox.confirm('是否删除这条回复？', '', {
                    confirmButtonText: '删除',
                    cancelButtonText: '取消',
                    type: 'warning',
                    beforeClose: async (action, instance, done) => {
                        if (action === 'confirm') {
                            instance.confirmButtonLoading = true;
                            instance.confirmButtonText = '删除中...';
                            const req = await app.ajax(bugApi.updateReply, {reply_id: item.reply_id});
                            done();
                            instance.confirmButtonLoading = false;
                            if (req.code === 1) {
                                app.alert('删除成功', 'success');
                                if (level === 1) {
                                    const {page, replyList} = this;
                                    replyList.length === 1 && (this.page = (page === 1) ? 1 : (page - 1));
                                    await this.getReplyList();
                                } else {
                                    await this.showItem(ind, 0, true);
                                }
                            } else {
                                app.alert(req.msg, 'error')
                            }
                        } else {
                            done();
                        }
                    }
                }).then(() => {
                }).catch(() => {
                });
            },
            // 计算经验等级
            getExpLevel(num){
                return app.getExpLevel(num);
            },
            // 富文本里的图片点击事件
            imageEnlargement(e){
                if (e.target.nodeName === 'IMG') {//判断点击富文本内容为img图片
                    const src = e.target.currentSrc;
                    window.open((src.includes('http') || src.includes('data:image')) ? src : (app.serverUrl + src))
                }
            }
        }
    }
</script>
<style lang="scss" scoped>
    .home {
        .ad-box {
            position: fixed;
            top: 80px;
            right: 20px;
        }
    }

    .detail-title {
        padding: 20px 0;
        font-size: 16px;
    }

    .detail-title-line {
        height: 4px;
    }

    .detail-list {
        .detail-content {
            padding: 10px 0;
            overflow: auto;
        }

        .detail-big, .detail-little {
            font-size: 13px;
            padding-bottom: 5px;

            .iconfont {
                font-size: 16px;
                margin-right: 5px;
            }
        }

        .detail-little {
            display: none;
        }

        .detail-info {
            font-size: 13px;
            padding-bottom: 5px;

            .detail-auth {
                flex-shrink: 0;

                .iconfont {
                    font-size: 10px;
                }

                .auth-name {
                    margin: 0 3px;
                }

                .info-level {
                    font-size: 18px;

                    .level-num {
                        position: absolute;
                        left: 50%;
                        top: 1px;
                        transform: translateX(-50%);
                        font-size: 10px;
                    }
                }
            }

            .collect-btn {
                font-size: 14px;
            }
        }

        .reply-box {
            background-color: #f5f5f5;
            padding: 0 10px;
            margin: 10px 30px;
            transition: all .3s;
        }

        .reply-item {
            padding: 10px 0;
            line-height: 1.5em;
        }

        .reply-input {
            padding: 10px 0;
        }
    }

    #editor-box {
        padding: 10px 0;
        z-index: 100;
        position: relative;
    }

    .detail-handle {
        padding: 10px 0 20px;
        z-index: 100;
        position: relative;
        justify-content: flex-end;
    }

    .replay-handle {
        padding-top: 8px;
        justify-content: flex-end;

        .replay-btn {
            padding: 5px 7px;
        }
    }

    @media screen and (max-width: 550px) {
        .detail-title {
            padding: 10px 0;
            font-size: 16px;
        }
        .detail-list {
            .detail-big {
                display: none;
            }

            .detail-little {
                display: flex;
            }

            .reply-box {
                margin: 10px 15px;
            }
        }
    }
</style>
